<template>
  <div v-if="canAccess('rp_verificacion')" class="card">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>

      <div class="p-grid p-fluid dashboard">
        <div class="p-col-12">
          <h6>Informe de verificación</h6>
        </div>
        <div class="p-col-12" >
          <div class="p-text-right p-fluid  p-ml-2">
            <div class="p-grid p-fluid  p-ml-2">
              <div class="p-col-12 p-md-2 p-mt-2" style="padding-top: 20px;">
                                  <span class="p-float-label df" v-if="isAdmin" style="margin-right: 1px;">
                                    <Dropdown  id="country" v-model="country" :options="countries" optionLabel="country"
                                               @change="filter();subsidiary=null;itemsTotal=[]"/>
                                    <label for="country">País</label>
                                </span>
              </div>
              <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
                      <span class="p-float-label df" >
                          <Dropdown id="subsidiary" v-model="subsidiary" :options="subsidiaries"
                                    @change="itemsTotal=[]"
                                    optionLabel="name"/>
                          <label for="subsidiary">Filial</label>
                      </span>
              </div>
              <div  style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
            <span class="p-float-label df" style="margin-right: 3px">
                          <Calendar autocomplete="off" :maxDate="new Date()" aria-autocomplete="false"
                                    id="start_date" :showIcon="true" v-model="date_start"
                                    dateFormat="yy-mm-dd"
                                    :yearNavigator="true" yearRange="2019:2040"/>
                          <label for="start_date">Fecha Inicio</label>
            </span>
              </div>
              <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-left: 3px">
                  <Calendar :maxDate="new Date()" :minDate="date_start" autocomplete="off"
                            id="end_date" :showIcon="true" v-model="date_end" dateFormat="yy-mm-dd"
                            :yearNavigator="true" yearRange="2019:2040" class="p-inputtext-sm"/>
                  <label for="end_date">Fecha Fin</label>
              </span>
              </div>
              <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
                <Button label="Buscar"  class="p-button-success  df"
                        @click="getData()"/>
              </div>
              <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
                <Button label="Exportar"  class="p-button-secondary df"
                        @click="exportReport('XLS')"/>

              </div>  <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
              <Button label="Imprimir" class="p-button-info df"
                      @click="exportReport('PDF')"/>

            </div>

            </div>
          </div>
        </div>
      </div>
        <div class="p-grid p-fluid dashboard" style="padding: 10px;background: white" v-for="(items,idx) in itemsTotal " :key="'tb'+idx">
          <div class="card card-w-title" id="divCard" style="padding:0px;min-width: 340px;width: 100%; overflow-x: auto;">
            <div v-for="(subsidiary,idxs) in items.subsidiaries " :key="'sub'+idxs" class="tableC">
              <DataTable :value="subsidiary.data" style="margin-top: 20px;">
                <ColumnGroup type="header">
                  <Row>
                    <Column style="padding: 6px!important;"
                            :header="items.country.toUpperCase()+ ' - FILIAL: '+subsidiary.name.toUpperCase()"
                            colspan="20"></Column>
                  </Row>
                  <Row>
                    <Column header="Fecha Ingreso" :rowspan="2" headerStyle="width: 90px" headerClass="thReport"/>
                    <Column header="Rem. No." :rowspan="2" headerStyle="width: 80px" headerClass="thReport"/>
                    <Column header="Matrícula" :rowspan="2" headerClass="thReport"/>
                    <Column header="Cédula" :rowspan="2" headerClass="thReport"/>
                    <Column header="Nombre Titular" :rowspan="2" headerClass="thReport"/>
                    <Column header="Apellido Titular" :rowspan="2" headerClass="thReport"/>
                    <Column header="Nombre Asesor" :rowspan="2" headerClass="thReport"/>
                    <Column header="Fecha Verificación" :rowspan="2" headerClass="thReport"/>
                    <Column header="Usuario Verificación" :rowspan="2" headerClass="thReport"/>
                    <Column header="Estado Matrícula" colspan="7" headerStyle="width: 220px;text-align:center" headerClass="thReport"/>
                    <Column header="Tipo Matrícula" :rowspan="2" headerClass="thReport"/>
                    <Column header="Medio Matrícula" :rowspan="2" headerClass="thReport"/>
                    <Column header="Plan" :rowspan="2"  headerStyle="width: 290px"/>
                    <Column header="Observaciones" :rowspan="2"  headerStyle="width: 290px"/>
                  </Row>
                  <Row>
                    <Column header="OK" headerClass="thReport" />
                    <Column header="Pen" headerClass="thReport"/>
                    <Column header="Dev" headerClass="thReport"/>
                    <Column header="Rec" headerClass="thReport"/>
                    <Column header="Anul." headerClass="thReport"/>
                    <Column header="Reing" headerClass="thReport"/>
                    <Column header="RB" headerClass="thReport"/>
                  </Row>
                </ColumnGroup>

                <Column field="registration" bodyClass="tdReport">
                  <template #body="slotProps">
                    {{
                      formatDate(slotProps.data.registration)
                    }}<span class="p-badge p-badge-warning p-m-1" v-if="slotProps.data.is_relocation==='S'">Reubicada</span>
                  </template>
                </Column>

                <Column field="referral_note" bodyClass="tdReport"/>
                <Column field="sequential" bodyClass="tdReport"/>
                <Column field="dni" bodyClass="tdReport"/>
                <Column field="name_holder" bodyClass="tdReport"/>
                <Column field="last_name_holder" bodyClass="tdReport"/>
                <Column field="employee" bodyClass="tdReport"/>
                <Column field="date_first_payment" bodyClass="tdReport"/>
                <Column field="verified" bodyClass="tdReport"/>
                <Column field="status" bodyClass="tdReport">
                  <template #body="slotProps">
                    <div style="text-align: center">   {{
                        slotProps.data.status == 'OK' ? 1 : ''
                      }}</div>

                  </template>
                </Column>
                <Column field="status" bodyClass="tdReport">
                  <template #body="slotProps">
                    <div style="text-align: center">   {{
                        slotProps.data.status == 'Pendiente' ? 1 : ''
                      }} </div>
                  </template>
                </Column>
                <Column field="status" bodyClass="tdReport">
                  <template #body="slotProps">
                    <div style="text-align: center">   {{
                        slotProps.data.status == 'Devuelta' ? 1 : ''
                      }}</div>
                  </template>
                </Column>
                <Column field="status" bodyClass="tdReport">
                  <template #body="slotProps">
                    <div style="text-align: center">   {{
                        slotProps.data.status == 'Rechazada' ? 1 : ''
                      }}</div>
                  </template>
                </Column>
                <Column field="status" bodyClass="tdReport">
                  <template #body="slotProps">
                    <div style="text-align: center">   {{
                        slotProps.data.status == 'Anulada' ? 1 : ''
                      }}</div>
                  </template>
                </Column>
                <Column field="status" bodyClass="tdReport">
                  <template #body="slotProps">
                    <div style="text-align: center">   {{
                        slotProps.data.status == 'Reingresada' ? 1 : ''
                      }}</div>
                  </template>
                </Column>
                <Column field="status" bodyClass="tdReport">
                  <template #body="slotProps">
                    <div style="text-align: center">   {{
                        slotProps.data.status == 'RBs' ? 1 : ''
                      }}</div>
                  </template>
                </Column>
                <Column field="type_mat" bodyClass="tdReport">
                  <template #body="slotProps">
                    <div style="text-align: center">
                      {{ slotProps.data.type_mat }} <span v-if="slotProps.data.transition>0">Pagos En Transici&oacute;n</span></div>
                  </template>
                </Column>

                <Column field="origin_mat" bodyClass="tdReport"/>
                <Column field="plan_c" bodyClass="tdReport"/>
                <Column field="observation" bodyClass="tdReport"/>

                <ColumnGroup type="footer">
                  <Row>
                    <Column footer="Totales:" :colspan="9" footerStyle="text-align:right"/>
                    <Column :footer="subsidiary.counters.ok" footerStyle="text-align:center"/>
                    <Column :footer="subsidiary.counters.pen" footerStyle="text-align:center"/>
                    <Column :footer="subsidiary.counters.dev" footerStyle="text-align:center"/>
                    <Column :footer="subsidiary.counters.rechazada" footerStyle="text-align:center"/>
                    <Column :footer="subsidiary.counters.anulada" footerStyle="text-align:center"/>
                    <Column :footer="subsidiary.counters.reingresada" footerStyle="text-align:center"/>
                    <Column :footer="subsidiary.counters.rb" footerStyle="text-align:center"/>
                    <Column footer="" :colspan="4" footerStyle="text-align:right"/>
                  </Row>
                </ColumnGroup>

              </DataTable>
            </div>
        </div>
        </div>


  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../helpers/bouncer";
import catalogService from "../../core/service/catalog.service";
import moment from "moment";
import service from "../service/report.service";
import FileSaver from 'file-saver';

export default {
  mixins: [bouncer],
  name: "VerificationReport",
  data() {
    return {
      date_end: null,
      date_start: null,
      date_end_max: null,
      subsidiary: null,
      country: null,
      countries: [],
      itemsTotal: [],
      service: service('verifications'),
      counters: {
        ok: 0,
        pen: 0,
        dev: 0,
        rec: 0
      }
    }
  },
  mounted() {
    if (this.canAccess('rp_verificacion')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Estadísticas', url: "javascript:void(0);"}]);

      const date = new Date();
      this.date_end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      this.date_end_max = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
      this.date_start = new Date(date.getFullYear(), date.getMonth(), 1);
      catalogService.get(`select/countries/subsidiaries`).then(response => {
        this.countries = response.data;
        this.loading = false;
        if (!this.isAdmin) {
          this.country = this.countries[0];
        }
      });

    }
  },
  methods: {
    formatDate(dat) {
      return moment(dat).format('YYYY-MM-DD');
    },
    filter() {
      this.loading = true;
      this.getCountries()
    },
    getCountries() {
      catalogService.get(`select/countries/subsidiaries`).then(response => {
        this.countries = response.data;
        this.loading = false;
      });
    },
    exportReport(command) {
      if(this.country==null){
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'El campo pais es obligatorio',
          life: this.$utils.toastLifeTime()
        });
        return;
      }
      this.loading = true;
      this.service.getVerifications({
        date_start: moment(this.date_start).format("YYYY-MM-DD"),
        date_end: moment(this.date_end).format("YYYY-MM-DD"),
        country: this.country?.id,
        subsidiary: this.subsidiary?.id,
        export: command
      })
          .then(x => {
            if(command=='PDF'){
              this.info = x.data;
              var byteCharacters = atob(x.data);
              var byteNumbers = new Array(byteCharacters.length);
              for (var i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              var byteArray = new Uint8Array(byteNumbers);
              var blob = new Blob([byteArray], {type: "application/pdf"});
              this.loading = false;
              FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
            }else{
              window.open(x.data.url_file)
            }

          }).catch((err) => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })

    },
    getData() {
      if(this.country==null && this.isAdmin){
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'El campo pais es obligatorio',
          life: this.$utils.toastLifeTime()
        });
      }else{
        if (!moment(this.date_start).isValid() || !moment(this.date_end).isValid()) {
          this.$toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Los campos de fecha son obligatorios para el reporte',
            life: this.$utils.toastLifeTime()
          });
        } else {
          if (moment(this.date_start) > moment(this.date_end)) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: 'El campo fecha hasta debe ser mayor o igual que el campo fecha desde',
              life: this.$utils.toastLifeTime()
            });
          } else {
            this.loading = true;
            this.itemsTotal=[]
            this.service.getVerifications({
              date_start: moment(this.date_start).format("YYYY-MM-DD"),
              date_end: moment(this.date_end).format("YYYY-MM-DD"),
              country: this.country?.id,
              subsidiary: this.subsidiary?.id
            }).then(x => {
              this.itemsTotal = x.data;
              if(x.data[0].subsidiaries.length===0){
                this.$toast.add({
                  severity: 'error',
                  summary: 'Error',
                  detail: 'No hay registros por visualizar con los valores escogidos',
                  life: this.$utils.toastLifeTime()
                });
              }
              this.loading = false;
            }).catch(err => {
              const message = err.response.data;
              let error = this.$utils.formatError(message);
              this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
            })
          }
        }
      }

    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    subsidiaries() {
      var subsidiaries= this.country ? this.country.subsidiaries : [];
      var elements=[];
      if(subsidiaries.length>0){
        var obj={
          id: null,
          employee_id: 0,
          city_id: subsidiaries[0].city_id,
          name: "Todas las Filiales",
          created_at: null,
          deleted_at: null,
          laravel_through_key: subsidiaries[0].laravel_through_key,
          updated_at: null};
        elements=[obj,...subsidiaries]
      }
      return elements;
    }
  }
}
</script>

<style>

.p-dropdown .p-dropdown-trigger {
  height: 33px;
}

.thReport {

  padding: 2px !important;
  border-width: 1px !important;
}

.tdReport {
  padding: 2px !important;
  border-width: 1px !important;
}

div.tableC>div>div>table{
  table-layout: auto !important;
}

.df{
  margin-left: 3px!important;
  margin-right: 4px!important;

}

span.p-calendar.p-component.p-inputwrapper.p-calendar-w-btn.p-inputwrapper-filled{
  height: 35px!important;
}

.p-dropdown-label{
  text-align: left;
}
</style>
